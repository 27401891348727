import { cn } from "~/lib/style";

interface Props {
	className?: string;
	showSpinner?: boolean;
}

export const Spinner = ({ className }: Props) => (
	<div
		className={cn(
			"h-10 w-10 animate-spin rounded-full border-4 border-border border-t-primary",
			className
		)}
	/>
);

export default Spinner;
